import { createDomMotionComponent } from "framer-motion";

/** IE 11 supported version of the motion component */
export const motion = {
  div: createDomMotionComponent("div"),
  span: createDomMotionComponent("span"),
  button: createDomMotionComponent("button"),
  li: createDomMotionComponent("li"),
  p: createDomMotionComponent("p"),
  path: createDomMotionComponent("path"),
  ul: createDomMotionComponent("ul"),
  img: createDomMotionComponent("img"),
};

export default motion;
