module.exports = {
  title: "Skara Sommarland",
  author: "Rebel and Bird",
  description: "Skara Sommarland",
  siteUrl: process.env.GATSBY_SITE_URL ? process.env.GATSBY_SITE_URL : "https://www.sommarland.se",
  locales: [
    { locale: "sv", default: true },
    { locale: "en", default: false },
    { localeAlias: "no", locale: "nn", default: false },
  ],
  timeZone: "Europe/Stockholm",
  timeUtcOffsetSummerTime: "+02:00",
  timeUtcOffsetNormalTime: "+01:00",
};
