class ServiceHelper {
  constructor() {
    this.apiBaseUrl = process.env.GATSBY_PRS_API_URL
      ? process.env.GATSBY_PRS_API_URL
      : "https://prs-cdp-dev-webapiproxy.azurewebsites.net/api/ssl/";

    if (!this.apiBaseUrl.endsWith("/")) {
      this.apiBaseUrl = `${this.apiBaseUrl}/`;
    }
  }

  fetchClient(url, options) {
    return new Promise((resolve, reject) =>
      fetch(url, options).then((response) => this.handleResponse(response, resolve, reject))
    );
  }

  handleResponse = (response, resolve, reject) => {
    if (response.ok) {
      resolve(response.json());
    } else if (response instanceof Object) {
      response
        .text()
        .then((text) => reject({ status: response.status, error: text ? JSON.parse(text) : response.statusText }))
        .catch((err) => reject({ status: response.status, error: err }));
    } else {
      reject({ status: 500 });
    }
  };

  fetchGet(url, extendOptions = null, customApiBaseUrl = null) {
    return this.fetchClient(this.buildApiUrl(url, customApiBaseUrl), this.extendOptionsFetchGet(extendOptions));
  }

  fetchPost(url, data, extendOptions = null, customApiBaseUrl = null) {
    return this.fetchClient(this.buildApiUrl(url, customApiBaseUrl), this.extendOptionsFetchPost(data, extendOptions));
  }

  fetchPatch(url, data, extendOptions = null, customApiBaseUrl = null) {
    return this.fetchClient(this.buildApiUrl(url, customApiBaseUrl), this.extendOptionsFetchPatch(data, extendOptions));
  }

  fetchDelete(url, data, extendOptions = null, customApiBaseUrl = null) {
    return this.fetchClient(
      this.buildApiUrl(url, customApiBaseUrl),
      this.extendOptionsFetchDelete(data, extendOptions)
    );
  }

  buildApiUrl(endpoint, customApiBaseUrl = null) {
    if (customApiBaseUrl && !customApiBaseUrl.endsWith("/")) {
      customApiBaseUrl = `${customApiBaseUrl}/`;
    }

    if (customApiBaseUrl) {
      return endpoint.startsWith("/")
        ? `${customApiBaseUrl}${endpoint.substring(1, endpoint.length)}`
        : `${customApiBaseUrl}${endpoint}`;
    }

    return endpoint.startsWith("/")
      ? `${this.apiBaseUrl}${endpoint.substring(1, endpoint.length)}`
      : `${this.apiBaseUrl}${endpoint}`;
  }

  extendOptionsFetchGet(extendOptions) {
    return extendOptions instanceof Object ? Object.assign({ method: "GET" }, extendOptions) : { method: "GET" };
  }

  extendOptionsFetchPost(data, extendOptions) {
    return extendOptions
      ? Object.assign({ method: "POST", body: JSON.stringify(data) }, extendOptions)
      : { method: "POST", body: JSON.stringify(data), headers: { "Content-Type": "application/json; charset=utf-8" } };
  }

  extendOptionsFetchPatch(data, extendOptions) {
    return extendOptions
      ? Object.assign({ method: "PATCH", body: JSON.stringify(data) }, extendOptions)
      : { method: "PATCH", body: JSON.stringify(data), headers: { "Content-Type": "application/json; charset=utf-8" } };
  }

  extendOptionsFetchDelete(data, extendOptions) {
    return extendOptions
      ? Object.assign({ method: "DELETE", body: JSON.stringify(data) }, extendOptions)
      : {
          method: "DELETE",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json; charset=utf-8" },
        };
  }
}

const helper = new ServiceHelper();
export default helper;
