import { Component, createContext } from "react";
import uniqueId from "lodash-es/uniqueId";
import { EventAggregator } from "@parksandresorts/core";
import { ADD_NOTIFICATION } from "components/block-elements/notification-center/constants/actions";

const defaultContextValue = {
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
};
const NotificationContext = createContext(defaultContextValue);
const NotificationContextConsumer = NotificationContext.Consumer;

class NotificationContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultContextValue,
      ...{
        addNotification: this.addNotification.bind(this),
        removeNotification: this.removeNotification.bind(this),
      },
    };

    EventAggregator.subscribe(ADD_NOTIFICATION, (info) => {
      if (!info) {
        return;
      }

      this.addNotification(info.headline, info.body, info.notificationType, info.displayTime, info.id);
    });
  }

  addNotification(headline, body, type, displayTime, id = null) {
    if (id && this.state.notifications.find((notification) => notification.id === id)) {
      return;
    }

    const notification = {
      id: id ? id : uniqueId("notification_id_"),
      headline: headline,
      body: body,
      displayTime: displayTime ? displayTime : undefined,
      notificationType: type,
    };

    const updatedNotifications = [...this.state.notifications];
    updatedNotifications.push(notification);

    this.setState({ notifications: updatedNotifications });
  }

  removeNotification(idToBeRemoved) {
    const notificationWithoutRemoved = this.state.notifications.filter(
      (notification) => notification.id !== idToBeRemoved
    );
    this.setState({ notifications: notificationWithoutRemoved });
  }

  setValue(value, callback) {
    this.setState(value, typeof callback === "function" ? callback() : null);
  }

  render() {
    return <NotificationContext.Provider value={this.state}>{this.props.children}</NotificationContext.Provider>;
  }
}

export { NotificationContext, NotificationContextConsumer, NotificationContextProvider };
