/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "whatwg-fetch";
import Waves from "node-waves";
import { GlobalContextProvider } from "components/stores/global-context";
import { JetPassContextProvider } from "components/stores/jetpass-context";
import { AuthContextProvider } from "@parksandresorts/myparks";
import EventAttributeStore from "components/stores/event-attribute-store.js";
import InteractionHelper from "components/helpers/interaction-helper.js";
import EventHub from "components/events/event-hub";
import LocationHelper from "components/helpers/location-helper.js";
import {
  SessionStorageHelper,
  extract,
  LocalStorageHelper,
  EventAggregator,
  CartContextProvider,
  LanguageHelper,
} from "@parksandresorts/core";
import StoreFactory from "components/factory/store-factory";
import { globalHistory } from "@reach/router";
import siteMetadata from "./site-metadata";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lockJson = require("./package-lock.json");

var deviceChannel;
var isWebView;
var nativeOS;
let historyAction;

if (typeof window !== "undefined") {
  deviceChannel = LocationHelper.getChannelFromQuery()
    ? LocationHelper.getChannelFromQuery()
    : LocalStorageHelper.getItem("device_channel");
  isWebView = window.navigator && window.navigator.userAgent === "webview";
  const urlParams = new URLSearchParams(window.location.search);
  nativeOS = urlParams.get("platform");

  if (deviceChannel === "android") {
    document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
  }
}

export const shouldUpdateScroll = ({ routerProps }) => {
  const disableScrollOnUpdate = extract(routerProps, "location.state.disableScrollOnUpdate", false);

  if (disableScrollOnUpdate && historyAction === "PUSH") {
    return false;
  }

  return true;
};

const printPackageVersions = () => {
  const core = lockJson.packages["node_modules/@parksandresorts/core"];
  const myparks = lockJson.packages["node_modules/@parksandresorts/myparks"];

  if (core) console.log(`@parksandresorts/core v${core.version}`);
  if (myparks) console.log(`@parksandresorts/myparks v${myparks.version}`);
};

export const onClientEntry = () => {
  printPackageVersions();

  if (typeof window !== "undefined") {
    if (!process.env.GATSBY_DISABLE_HTTPS_REDIRECT && window.location.protocol !== "https:") {
      window.location.href = "https:" + window.location.href.substring(window.location.protocol.length);
    }

    if (isWebView) {
      document.documentElement.classList.add("webview");
      SessionStorageHelper.setItem("isWebView", true);
      InteractionHelper.setInteractionAsTouch(true);
    }

    if (nativeOS) {
      LocalStorageHelper.setItem("nativeOS", nativeOS);
    }

    globalHistory.listen(({ action }) => {
      historyAction = action;
    });

    if (deviceChannel) {
      document.documentElement.classList.add(deviceChannel);
      LocalStorageHelper.setItem("device_channel", deviceChannel);
      InteractionHelper.setInteractionAsTouch(true);
    } else {
      InteractionHelper.setDefaultState();
    }

    Waves.init({ delay: 100 });

    window.addEventListener("popstate", () => (EventAttributeStore.isPopState = true));
    window.addEventListener("orientationchange", (e) => EventAggregator.publish("onOrientationChange", e));

    window.addEventListener("resize", (e) => {
      EventAttributeStore.windowWidth =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      EventAttributeStore.windowHeight =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      EventAggregator.publish("onResizeWindowWidth", EventAttributeStore.windowWidth);
      EventAggregator.publish("onResizeWindowHeight", EventAttributeStore.windowHeight);

      if (deviceChannel === "android") {
        document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
      }
    });

    window.addEventListener("scroll", () => {
      EventAttributeStore.scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      EventAggregator.publish("onScroll", EventAttributeStore.scrollY);
    });

    window.addEventListener("touchmove", () => {
      let scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop !== EventAttributeStore.scrollY) {
        EventAttributeStore.scrollY = scrollTop;
        EventAggregator.publish("onScroll", EventAttributeStore.scrollY);
      }
    });

    const receiver = nativeOS === "android" || LocalStorageHelper.getItem("nativeOS") === "android" ? document : window;

    receiver &&
      receiver.addEventListener("message", (event) => {
        if (event.data === "toggleMenu") {
          EventAggregator.publish("onToggleMenu");
        }
      });
    EventAggregator.subscribe("trackEvent", (data) => EventHub.trackEvent(data));
    EventAggregator.subscribe("trackEcommerce", (data) => EventHub.trackEcommerce(data));
    EventAggregator.subscribe("trackPageview", (data) => EventHub.trackPageview(data));

    LanguageHelper.setAvailableLanguages(siteMetadata.locales);
  }
};

export const wrapRootElement = ({ element }) => (
  <StoreFactory>
    <CartContextProvider>
      <GlobalContextProvider>
        <AuthContextProvider>
          <JetPassContextProvider>{element}</JetPassContextProvider>
        </AuthContextProvider>
      </GlobalContextProvider>
    </CartContextProvider>
  </StoreFactory>
);

export const onRouteUpdate = ({ location }) => {};
