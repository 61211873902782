import { graphql, useStaticQuery } from "gatsby";
import { ProductStore, ShowStore, extract, RideStore, TextBlockStore, RestaurantStore } from "@parksandresorts/core";

const StoreFactory = (props) => {
  const data = useStaticQuery(query);

  ProductStore.populateStore(extract(data, "allContentfulProductBlock.edges"));
  ShowStore.populateStore(extract(data, "allContentfulShowBlock.edges"));
  RideStore.populateStore(extract(data, "allContentfulRideBlock.edges"));
  RestaurantStore.populateStore(data?.allContentfulRestaurantBlock?.edges || []);
  TextBlockStore.populateStore(extract(data, "allContentfulTextBlock.nodes"));

  return <>{props.children}</>;
};

const query = graphql`
  {
    allContentfulProductBlock(filter: { productName: { ne: null } }) {
      edges {
        node {
          ...ProductBlockStore
        }
      }
    }
    allContentfulShowBlock {
      edges {
        node {
          contentful_id
          node_locale
          internalTitle
          id
          sortIndex
          preHeading
          title
          preamble {
            preamble
          }
          label
          shortDescriptionInList
          pageLink {
            slug
          }
          imageInList {
            file {
              url
            }
            gatsbyImageData(width: 132, height: 132, layout: FIXED, resizingBehavior: FILL)
          }
          tags {
            title
          }
          length
          location
          product {
            contentful_id
            productPageLink {
              slug
            }
          }
          mapLink {
            id
            title
            smallDeviceTitle
            internalLink {
              slug
            }
            externalUrl
            openInNewTab
            image {
              file {
                url
              }
            }
            asset {
              file {
                url
              }
            }
            icon
          }
          showInPurchaseFlow
          showProductDescriptionTextInPurchaseFlow
        }
      }
    }
    allContentfulRideBlock(filter: { title: { ne: null } }) {
      edges {
        node {
          ...RideBlockStore
        }
      }
    }
    allContentfulRestaurantBlock(filter: { title: { ne: null } }) {
      edges {
        node {
          node_locale
          ...RestaurantBlock
        }
      }
    }
    allContentfulTextBlock(filter: { richText: { raw: { ne: null } } }) {
      nodes {
        id
        contentful_id
        node_locale
        internalTitle
        componentTheme
        richText {
          raw
          references {
            __typename
            ...Link
            ...Asset
          }
        }
      }
    }
  }
`;

export default StoreFactory;
