import { Component } from "react";
import { motion } from "polyfill/motion-polyfill/motion-polyfill";
import { AnimatePresence } from "framer-motion";
import Notification from "components/block-elements/notification/notification";
import styles from "./notification-center.module.less";
import { TIME_PERSISTENT } from "./constants/time-types";
import { extract } from "@parksandresorts/core";

const NOTIFICATION_DEFAULT_TIME = 5;

export default class NotificationCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationsToBeRemoved: [], //to remember what we have timers for
    };
  }

  removeNotification(id) {
    const foundId = this.state.notificationsToBeRemoved.includes(id);

    if (foundId) {
      this.props.removeNotification(id);

      const idsWithoutRemoved = this.state.notificationsToBeRemoved.filter((removeId) => removeId !== id);
      this.setState({ notificationsToBeRemoved: idsWithoutRemoved });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const previousNotifications = extract(prevProps, "notifications");
    const newNotifications = extract(this.props, "notifications");

    for (const notification of newNotifications) {
      const foundNotification = previousNotifications.find((noti) => noti.id === notification.id) ? true : false;
      if (!foundNotification) {
        const newState = [...this.state.notificationsToBeRemoved];
        newState.push(notification.id);

        this.setState({ notificationsToBeRemoved: newState });

        if (notification.displayTime !== TIME_PERSISTENT) {
          const notificationDisplayTime = extract(notification, "displayTime");
          const timeOutTime = notificationDisplayTime
            ? notificationDisplayTime * 1000
            : NOTIFICATION_DEFAULT_TIME * 1000;
          setTimeout(() => this.removeNotification(notification.id), timeOutTime);
        }
      }
    }
  }

  render() {
    const { notifications } = this.props;

    return (
      <div className={styles.wrapper}>
        <ul className={styles.notificationList}>
          <AnimatePresence initial={false}>
            {notifications.map((notification, index) => (
              <motion.li
                layout={true}
                key={notification.id}
                className={styles.notificationItem}
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
                exit={{ opacity: 0, y: 100, transition: { duration: 0.2 } }}
              >
                <Notification
                  headline={notification.headline}
                  body={notification.body}
                  notificationType={notification.notificationType}
                  displayTime={notification.displayTime}
                  onClose={() => this.removeNotification(notification.id)}
                />
              </motion.li>
            ))}
          </AnimatePresence>
        </ul>
      </div>
    );
  }
}

NotificationCenter.propTypes = {};
