import { motion } from "polyfill/motion-polyfill/motion-polyfill";
import styles from "./notification.module.less";
import {
  STANDARD_NOTIFICATION,
  SUCCESS_NOTIFICATION,
  WARNING_NOTIFICATION,
} from "components/block-elements/notification-center/constants/notification-types";

const Notification = ({ headline, body, notificationType = STANDARD_NOTIFICATION, onClose }) => {
  const getNotificationIcon = () => {
    switch (notificationType) {
      case SUCCESS_NOTIFICATION:
        return <span className={[styles.leftIcon, "icon-check"].join(" ")} />;
      case WARNING_NOTIFICATION:
        return <span className={[styles.leftIcon, "icon-error-outline"].join(" ")} />;
      default:
        return <span className={[styles.leftIcon, "icon-info"].join(" ")} />;
    }
  };

  const getNotificationTypeClass = () => {
    switch (notificationType) {
      case SUCCESS_NOTIFICATION:
        return styles.success;
      case WARNING_NOTIFICATION:
        return styles.warning;
      default:
        return styles.standard;
    }
  };

  return (
    <motion.div className={[styles.wrapper, getNotificationTypeClass()].join(" ")} onClick={onClose}>
      <div className={styles.iconWrapper}>{getNotificationIcon()}</div>
      <div className={styles.textWrapper}>
        <h5 className={styles.headline}>{headline}</h5>
        <p className={styles.body}>{body} </p>
      </div>
      <span className={[styles.closeIcon, "icon-close"].join(" ")} />
      <motion.div className={styles.bottomBorder} />
    </motion.div>
  );
};

export default Notification;
